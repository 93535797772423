require("babel-polyfill");
require('bootstrap');
require('jquery.growl/javascripts/jquery.growl');
require('slick-carousel');
global.Translator = require('bazinga-translator');
var Translator = global.Translator;
global.jQuery = global.$ = require('jquery');
global.lightbox = require('lightbox2');
require('bootstrap-markdown/js/bootstrap-markdown');
require('./jquery.ajaxsubmit.js');
require('./jquery.nicefileinput.js');
require('./forum/smiley.js');
global.Cookies = require('js-cookie');
global.Showdown = require('showdown');
global.ShowdownTwitterExtension = require('showdown-twitter');
global.showdownemoji = require('showdown-emoji');
global.jconfirm = require('jquery-confirm');
global.axios = require('axios');
global.mobileDevice = require('mobile-device-detect');

/* PIM Products */
require('datatables.net-bs4/css/dataTables.bootstrap4.css');
require('datatables.net-bs4');
require('./jquery.highlight.js');
require('./product/product');
/* end */

require('./vue_frontend.js');
global.Vue = require('vue').default;
global.VueAxios = require('vue-axios');
global.BootstrapVue = require('bootstrap-vue');
global.VueRouter = require('vue-router');
Vue.use(VueAxios, axios, BootstrapVue, VueRouter);

Vue.component('datepicker', require('vuejs-datepicker').default);

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
	symbol: '€',
	thousandsSeparator: '.',
	fractionCount: 2,
	fractionSeparator: ',',
	symbolPosition: 'front',
	symbolSpacing: true
});

import VAutocomplete from 'v-autocomplete';
Vue.use(VAutocomplete);
import templateProductSearch from '../vue/blocks/product_search';
global.templateProductSearch = templateProductSearch;

global.xmlParser = require('fast-xml-parser');

require('./blocks/product_search');
require('./blocks/products.js');
require('./blocks/product.js');
require('./blocks/cart.js');
require('./blocks/cart_item.js');
require('./blocks/product_node.js');
global.GoogleMapsLoader = require('google-maps'); // only for common js environments
require('./blocks/local_business.js');

$.fn.vueFrontend();

$(document).ready(function() {
	$('[data-toggle="popover"]').popover();
});

require('./blocks/popup_contact.js');
require('./blocks/text_carousel.js');
require('./blocks/gallery.js');
require('./blocks/products_by_category.js');
require('./blocks/category.js');
require('./blocks/menu.js');
require('./general');
// require('./routing/fos_js_routes');

global.balloon = require('./media/balloon/balloon.js');

$('form[in-block="in-block"]').blockFormSubmit();
$('.cms-block.form-jquery .action_to_cart_form').blockCart();
$.fn.blockCart.init();

$(document).ready(function() {
	for (var i = 0; i < functionsOnLoad.length; i++) {
		functionsOnLoad[i]($);
	}
});
