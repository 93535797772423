functionsOnLoad.push(function() {
    $('.cms-block-products a[data-change-image]').on('click', function() {
        // var isPcTablet = window.matchMedia("only screen and (min-width: 1025px)");

        // if (isPcTablet.matches) {
        var dataId = $(this).attr('data-id');

        $(this)
            .closest('.cms-block-products')
            .find('div[data-media][data-id]')
            .addClass('d-none');
        $(this)
            .closest('.cms-block-products')
            .find('div[data-media][data-id=' + dataId + ']')
            .removeClass('d-none');

        $(this)
            .closest('.cms-block-products')
            .find('div.products-in-set > .row[data-id]')
            .removeClass('clicked');
        $(this)
            .closest('.cms-block-products')
            .find('div.products-in-set > .row[data-id=' + dataId + ']')
            .addClass('clicked');

        $(this)
            .closest('.cms-block-products')
            .find('div.products-in-set > .row[data-id] a.btn-action-to-cart')
            .removeClass('btn-primary')
            .addClass('btn-secondary');

        $(this)
            .closest('.cms-block-products')
            .find('a[data-form-id=add_to_cart_form_' + dataId + ']')
            .removeClass('btn-secondary')
            .addClass('btn-primary');

        piece = $(this)
            .closest('div[data-mark]')
            .attr('data-mark');

        $('.balloon-view .globo').css('background-color', 'var(--primary)');
        $('.balloon-view .globo[data-globo-id=' + piece + ']').css('background-color', 'var(--secondary)');
        $('.product-in-set-selected').removeClass('d-none');

        return false;
        //Conditional script here
        //  }
    });

});
products_component = {
	methods: {
		refresh: function(event) {
			var data = this.settings;
			var url = Routing.generate('api.products') + window.location.search;
			var that = this;
			$('#loading').removeClass('d-none');
			$.ajax({
				url: url,
				type: 'POST',
				dataType: 'json',
				data: data,
				success: function(data) {
					$('#loading').addClass('d-none');
					if (data.status === 'ok') {
						that.products = data.products;
					}
				}
			});
		},
		changeAttribute: function(event) {
			var target = event.target;
			productId = $(target).attr('data-product');
			var attributes = {};
			$(target)
				.closest('.product')
				.find('select[data-select-attribute]')
				.each(function(e) {
					attribute = $(this).attr('data-attribute');
					attributes[attribute] = parseInt($(this).val());
				});

                        attribute = $('[name=add_to_cart_form_' + this.viewMode+'][data-product-id=' + productId + ']').find('.attributes')[0];
			$(attribute).val(JSON.stringify(attributes));

            for (const [key, product] of Object.entries(this.products)) {
            	if (product.id == productId) {
                    this.products[key].price = '';
                    this.products[key].sku = '';
                    for (var variation of product.variations) {
                        var attribs = {};
                        // variation.attributes.forEach(function(attributeValue) {
                        //     attribs[attributeValue.attribute.id] = attributeValue.id;
                        // });
                        for (const [key2, attribute] of Object.entries(variation.attributes)) {
                            if (attribute !== null && attribute.id !== undefined && attribute.attribute !== undefined && attribute.attribute.id !== undefined) {
                            	attribs[attribute.attribute.id] = attribute.id;
                            }
                        };
                        if (JSON.stringify(attribs) == JSON.stringify(attributes)) {
                            this.products[key].price = variation.price;
                            this.products[key].sku = variation.sku;
                            if (variation.stocks[0] !== undefined) {
                                this.products[key].stocks[0] = variation.stocks[0];
                            }

                            break;
                        }
                    }
            		break;
				}
            }
            // this.$forceUpdate();
		},
		changeSort: function(event) {
			this.settings.sort = event.target.value;
			this.refresh(event);
		},
		addToCartFormSubmit: function(event) {
			function operate_cart_item(action, reference, attributes, source, quantity, itemId, form) {
				var data = {
					reference: reference,
					source: source,
					quantity: quantity,
					itemId: itemId,
					attributes: attributes
				};
				if (action == 'add') {
					var url = Routing.generate('frontend.products.cart.item.quantity.' + action);
				} else if (action == 'remove') {
					var url = Routing.generate('frontend.products.cart.item.' + action);
				}



				$.ajax({
					url: url,
					type: 'POST',
					dataType: 'json',
					data: data,
					success: function(data) {
						if (data.status === true) {
							// function .... $.fn.blockCart.updateCartCounter();

							var urlCartCounter = Routing.generate('frontend.products.cart.counter') + '?nocache=' + Math.random() * 1000000;

							$.get(urlCartCounter, function(data) {
								cartCounter = document.getElementById('cart-counter');
								if (cartCounter !== null) {
									cartCounter.textContent = ' (' + data.counter + ')';
								}
							});
							$('#item_added_to_cart_message').show();
							message = Translator.trans('alert.label_' + action + '_item');
							// $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
							$.alert({
								title: Translator.trans('alert.label_alert_success'),
								content: message,
								// autoClose: 'ok|300',
								theme: 'modern',
								type: 'success',
								buttons: {
									ok: {
										// isHidden: true,
										text: Translator.trans('button.okey'),
										btnClass: 'btn-primary'
									}
								}
							});
						} else {
							message = Translator.trans('alert.label_failed_' + action + '_item');
							//$.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
							$.alert({
								title: Translator.trans('alert.label_alert_error'),
								content: message,
								// autoClose: 'ok|300',
								theme: 'modern',
								type: 'red',
								buttons: {
									ok: {
										// isHidden: true,
										text: Translator.trans('button.okey'),
										btnClass: 'btn-danger'
									}
								}
							});
						}
						for (const [key, value] of Object.entries(data)) {
							if (key.substr(0, 11) == 'remove-row-') {
								$(form)
									.closest('.' + key.substr(7))
									.remove();
							}
						}
					}
				});
			}

            form = $('#add_to_cart_form_' + this.viewMode);
            if (form != undefined) {
                form = $(event.target)
                    .closest('.product')
                    .find('form');
            }

			selects = $(form)
				.closest('.product')
				.find('[data-select-attribute]');
			if (selects.length > 0) {
				event = { target: $(selects[0]) };
				this.changeAttribute(event);
			}

			var reference = $(form)
				.find('.reference')
				.val();
			var attributes = $(form)
				.find('.attributes')
				.val();
            var source = $(form)
                .find('.source')
                .val();
			var hasStock = !$(form).closest('.product-details').find('.stock').hasClass('no-stock');
			var quantity_input = $(form).find('.quantity');
			// var itemId = $(form).attr('data-item-id');
			var itemId = $(form)
				.find('.reference')
				.val();
			var quantity = quantity_input.val();
			var action = $(form).attr('data-action');
			if (action == 'add') {
				quantity_input.val(0);
			}
			if (quantity > 0) {
				console.log(hasStock);
				if (this.product.price == 0 || !hasStock) {
					message = Translator.trans('alert.label_not_available');
					$.alert({
						title: Translator.trans('alert.label_alert_error'),
						content: message,
						// autoClose: 'ok|300',
						theme: 'modern',
						type: 'red',
						buttons: {
							ok: {
								// isHidden: true,
								text: Translator.trans('button.okey'),
								btnClass: 'btn-danger'
							}
						}
					});
				} else {
					operate_cart_item(action, reference, attributes, source, quantity, itemId, form);
				}
			} else {
				message = Translator.trans('alert.label_quantity_minimum');
				// $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
				$.alert({
					title: Translator.trans('alert.label_alert_error'),
					content: message,
					// autoClose: 'ok|300',
					theme: 'modern',
					type: 'red',
					buttons: {
						ok: {
							// isHidden: true,
							text: Translator.trans('button.okey'),
							btnClass: 'btn-danger'
						}
					}
				});
			}
		},
		itemSelected(item) {
			this.q = item.name;
		},
		itemClicked(item) {},
		getLabel(item) {
			if (item) {
				return item.name;
			}
			return '';
		},
		update(text) {
			var result = [];
			for (var i in this.categories) {
				if (new RegExp(text.toLowerCase()).test(this.categories[i].name.toLowerCase())) {
					result.push(this.categories[i]);
				}
			}
			this.items = result;
		}
	},
	created: function(event) {
		this.refresh(event);
	},
    filters: {
        tagsStockLevels: function (value, stockLevels, price) {
            tags = ["stock"];
            if (price == 0) {
                tags.push("no-stock");
            } else {
                if (value[0] !== undefined) {
                    if (value[0].stock == 0) {
                        tags.push("no-stock");
                    } else {
                        for (var key in stockLevels) {
                            if (stockLevels.hasOwnProperty(key)) {
                                v = Object.values(stockLevels[key])[0];
                                if (v >= value[0].stock) {
                                    tags.push(Object.keys(stockLevels[key])[0]);
                                    break;
                                }
                            }
                        }
                    }
                    if (tags.length == 1) {
                        tags.push('regular');
                    }
                }
            }

            return tags.join(' ');
        },
        labelStock: function (value, directValue) {
        	if (value[0] !== undefined) {
                if (value[0].stock == 0) {
                    label = 'label.out_stock';
                } else {
                    label = 'label.stock';
                }
                trans = Translator.trans(label, {}, 'frontend');
                if (trans == label && directValue !== undefined) {
                    trans = directValue;
                }

                return trans;
            }
        }
    },
    data: {
        viewMode: 'block',
        product: '',
        products: {},
        template: templateProductSearch,
        sort: 'featured',
    }
};
