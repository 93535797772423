product_component = {
    methods: {
        changeAttribute: function(event) {
            var target = event.target;
            var attributes = {};
            $(target).closest('.product').find('select[data-select-attribute]').each(function(e) {
                attribute = $(this).attr('data-attribute');
                attributes[attribute] = parseInt($(this).val());
            });
            var blockId = $(target).closest('.cms-block').attr('id').split('-').pop(-1);
            console.log(attributes);
            attribute  = $('#add_to_cart_form_' + this.product.id).find('.attributes')[0];
            $(attribute).val(JSON.stringify(attributes));
            var that = this;
            $(target).closest('.product').find('#btn-add-' + blockId + '-' + that.product.id).attr("disabled", "disabled");
            that.product.price = 0;
            that.product.sku = '';
            for (var variation of this.product.variations) {
                var attribs = {};
                variation.attributes.forEach(function(attributeValue) {
                    attribs[attributeValue.attribute.id] = attributeValue.id;
                });
                if (JSON.stringify(attribs) == JSON.stringify(attributes)) {
                    that.product.price = variation.price;
                    that.product.sku = variation.sku;
                    if (variation.stocks[0] !== undefined) {
                        that.product.stocks[0] = variation.stocks[0];
                    }
                    // TODO: Redo the block Gallery using vuejs, get data from controller and remove this attr -> src
                    if (typeof variation.thumbnailPath == 'string' && typeof variation.imagePath == 'string') {
                        $($('.gallery-slick-v1 .slider-nav img.slider-hover')[0]).attr('src', variation.thumbnailPath);
                        $($('.gallery-slick-v1 .slider-main-view img.slider-view')[0]).attr('src', variation.imagePath);
                        $($('.gallery-slick-v1 .slider-main-view a[data-lightbox]')[0]).attr('href', variation.imagePath);
                    }
                    if (that.product.stocks[0].stock > 0) {
                        $(target).closest('.product').find('#btn-add-' + blockId + '-' + that.product.id)
                            .removeAttr("disabled");
                    } else {
                        $(target).closest('.product').find('#btn-add-' + blockId + '-' + that.product.id)
                            .attr("disabled");
                    }
                    break;
                }
            }
            that.$forceUpdate();
            this.product.variations.forEach(function(variation) {
            });
        },
        addToCartFormSubmit: function (event) {
            function operate_cart_item(action, reference, attributes, source, quantity, itemId, form) {
                var data = {'reference': reference, 'source': source, 'quantity': quantity, 'itemId': itemId, 'attributes': attributes};
                if (action == "add") {
                    var url = Routing.generate('frontend.products.cart.item.quantity.' + action);
                } else if (action == "remove") {
                    var url = Routing.generate('frontend.products.cart.item.' + action);
                }
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: data,
                    success: function (data) {
                        if (data.status === true) {
                            // function .... $.fn.blockCart.updateCartCounter();
                            var urlCartCounter = Routing.generate('frontend.products.cart.counter') + '?nocache=' + Math.random() * 1000000;

                            $.get(urlCartCounter, function (data) {
                                cartCounter = document.getElementById('cart-counter');
                                if (cartCounter !== null) {
                                    cartCounter.textContent = ' (' + data.counter + ')';
                                }
                            });
                            $('#item_added_to_cart_message').show();
                            message = Translator.trans("alert.label_" + action + "_item");
                            // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_success"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'success',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-primary'
                                    }
                                }
                            });
                        } else {
                            message = Translator.trans("alert.label_failed_" + action + "_item");
                            //$.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_error"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'red',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-danger'
                                    }
                                }
                            });
                        }
                        for (const [key, value] of Object.entries(data)) {
                            if (key.substr(0, 11) == "remove-row-") {
                                $(form).closest('.' + key.substr(7)).remove();
                            }
                        }
                    }
                });
            }

            form = $(event.target).closest('.product').find('form');

            selects = $(form).closest('.product').find('[data-select-attribute]');
            if (selects.length > 0) {
                event = {target: $(selects[0])}
                this.changeAttribute(event);
            }

            var reference = $(form).find('.reference').val();
            var attributes = $(form).find('.attributes').val();
            var source = $(form).find('.source').val();
            var quantity_input = $(form).find('.quantity');
            // var itemId = $(form).attr('data-item-id');
            var itemId = $(form).find('.reference').val();
            var quantity = parseInt(quantity_input.val());
            var action = $(form).attr('data-action');
            if (action == 'add') {
                quantity_input.val(0);
            }
            if (quantity > 0) {
                console.log(quantity);
                if (this.product.price == 0 || this.product.stocks[0].stock < quantity) {
                    message = Translator.trans("alert.label_not_available");
                    $.alert({
                        title: Translator.trans("alert.label_alert_error"),
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                } else {
                    operate_cart_item(action, reference, attributes, source, quantity, itemId, form);
                }
            } else {
                message = Translator.trans("alert.label_quantity_minimum");
                // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                $.alert({
                    title: Translator.trans("alert.label_alert_error"),
                    content: message,
                    // autoClose: 'ok|300',
                    theme: 'modern',
                    type: 'red',
                    buttons: {
                        ok: {
                            // isHidden: true,
                            text: Translator.trans("button.okey"),
                            btnClass: 'btn-danger'
                        }
                    }
                });
            }
        },
        itemSelected (item) {
            this.q = item.name;
        },
        itemClicked (item) {
        },
        getLabel (item) {
            if (item) {
                return item.name
            }
            return '';
        },
        update (text) {
            var result = [];
            for(var i in this.categories) {
                if  ((new RegExp(text.toLowerCase())).test(this.categories[i].name.toLowerCase())) {
                    result.push(this.categories[i]);
                }
            }
            this.items = result;
        }
    },
    filters: {
        tagsStockLevels: function (value, stockLevels, price) {
            tags = ["stock"];
            if (price == 0) {
                tags.push("no-stock");
            } else {
                if (value[0] !== undefined) {
                    if (value[0].stock == 0) {
                        tags.push("no-stock");
                    } else {
                        for (var key in stockLevels) {
                            if (stockLevels.hasOwnProperty(key)) {
                                v = Object.values(stockLevels[key])[0];
                                if (v >= value[0].stock) {
                                    tags.push(Object.keys(stockLevels[key])[0]);
                                    break;
                                    }
                            }
                        }
                    }
                    if (tags.length == 1) {
                        tags.push('regular');
                    }
                }
            }

            return tags.join(' ');
        },
        labelStock: function (value, directValue) {
            if (value[0] !== undefined) {
                if (value[0].stock == 0) {
                    label = 'label.out_stock';
                } else {
                    label = 'label.stock';
                }
                trans = Translator.trans(label, {}, 'frontend');
                if (trans == label && directValue !== undefined) {
                    trans = directValue;
                }

                return trans;
            }
        }
    },
    data: {
    }

};
