var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("span", [_vm._v(_vm._s(_vm.item.name))]),
    _vm._v(" "),
    _c("abbr", [_vm._v(_vm._s(_vm.item.description))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }