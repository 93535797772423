$.fn.blockCart = function() {
    $(this).on("submit", function (e) {
        if (e !== undefined) {
            selects = $(this)
                .closest('.product')
                .find('[data-select-attribute]');
            if (selects.length > 0) {
                event = { target: $(selects[0]) };
                $.fn.blockCart.changeAttribute(event);
            }
            e.preventDefault();
            var reference = $(this).find('#add_to_cart_form_reference').val();
            var extraData = $(this).find('#add_to_cart_form_extraData').val();
            var source = $(this).find('#add_to_cart_form_source').val();
            var quantity_input = $(this).find('#add_to_cart_form_quantity');
            var price = parseInt($(this).closest('.product').find('.price').html());
            var stock = parseInt($(this).closest('.product').find('.stock').html());
            var hasStock = !$(this).closest('.product').find('.stock').hasClass('no-stock');
            var itemId = $(this).attr('data-item-id');
            var quantity = parseInt(quantity_input.val());
            var action = $(this).attr('data-action');
            var noCheckAvailable = $(this).attr('data-no-check-available') !== undefined;
            var attributes = $(this).find('.attributes').val();
            if (action == 'add') {
                quantity_input.val(0);
            }
            if (quantity > 0) {
                if (price == 0 || (!noCheckAvailable && stock < quantity) || !hasStock) {
                    message = Translator.trans("alert.label_not_available");
                    $.alert({
                        title: Translator.trans("alert.label_alert_error"),
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                } else {
                    operate_cart_item(action, reference, attributes, source, quantity, itemId, this, extraData);
                }
            } else {
                message = Translator.trans("alert.label_quantity_minimum");
                // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                $.alert({
                    title: Translator.trans("alert.label_alert_error"),
                    content: message,
                    // autoClose: 'ok|300',
                    theme: 'modern',
                    type: 'red',
                    buttons: {
                        ok: {
                            // isHidden: true,
                            text: Translator.trans("button.okey"),
                            btnClass: 'btn-danger'
                        }
                    }
                });
            }
        }

        return false;
    });

    function operate_cart_item(action, reference, attributes, source, quantity, itemId, form, extraData) {
        var data = {
            'reference': reference, 'attributes': attributes, 'source': source, 'quantity': quantity,
            'itemId': itemId, 'extraData': extraData
        };
        if (action == "add" || action == "update") {
            var url = Routing.generate('frontend.products.cart.item.quantity.' + action);
        } else if (action == "remove") {
            var url = Routing.generate('frontend.products.cart.item.' + action);
        }
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: data,
            success: function (data) {
                if (data.status === true) {
                    $.fn.blockCart.updateCartCounter();
                    $('#item_added_to_cart_message').show();
                    if (data.product !== undefined) {
                        let row = null;
                        $('.cms-block-cart .row-cart-item').each(function(n) {
                            element = $('.cms-block-cart .row-cart-item')[n];
                            if ($(element).find('.reference').text().trim() == data.product.reference) {
                                row = element;
                            }
                        });
                        isNew = false;
                        if (row == null) {
                            rowCloned = $('.cms-block-cart .row-cart-item').last().clone();
                            $('.cms-block-cart .row-cart-item').last().after(rowCloned);
                            row = $('.cms-block-cart .row-cart-item').last();
                            isNew = true;
                        }
                        if (!isNew) {
                            $(row).find('#add_to_cart_form_quantity').val(data.item.quantity);
                        } else {
                            $(row).find('.name a')
                                .html(data.product.name)
                                .attr('href', data.product.url);
                            $(row).find('.reference').html(data.product.reference);
                            $(row).find('.media a:not(:has(img))').attr('href', data.product.url);
                            if (data.product.image != null) {
                                $(row).find('.media a:has(img)').attr('href', data.product.image.largeUrl);
                                $(row).find('.media a img')
                                    .attr('src', data.product.image.mediumUrl)
                                    .attr('alt', data.product.image.alt)
                                    .attr('title', data.product.image.title);
                            }
                            $(row).find('.price').html('0,00 ' + currency);
                            $(row).find('.buttons .btn-action-delete')
                                .attr('data-item-id', data.item.id)
                                .attr('data-form-id', 'update_to_cart_form_' + data.product.id)
                            $(row).find('.buttons .btn-action-update')
                                .attr('data-form-id', 'update_to_cart_form_' + data.product.id)
                                .attr('data-id', data.item.id);
                        }

                    }
                    message = Translator.trans("alert.label_" + action + "_item");
                    // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                    $.alert({
                        title: Translator.trans("alert.label_alert_success"),
                        content: message,
                        autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'success',
                        buttons: {
                            ok: {
                                isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-primary'
                            }
                        }
                    });

                    window.location.reload();
                } else {
                    failed = data.error !== undefined ? data.error : action + "_item";
                    message = Translator.trans("alert.label_failed_" + failed);
                    //$.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                    $.alert({
                        title: Translator.trans("alert.label_alert_error"),
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                }
                for (const [key, value] of Object.entries(data)) {
                    if (key.substr(0, 11) == "remove-row-") {
                        $(form).closest('.' + key.substr(7)).remove();
                    }
                }
                if ((action == "remove" || action == "update") && $('.cms-block-cart').find('.totals').length > 0) {
                    window.location.reload();
                }
            }
        });
    }
};

$.fn.blockCart.changeAttribute = function(event) {
    var target = event.target;
    var attributes = {};
    $(target)
        .closest('.product')
        .find('select[data-select-attribute]')
        .each(function(e) {
            attribute = $(this).attr('data-attribute');
            attributes[attribute] = $(this).val();
        });

    attribute = $('#add_to_cart_form_' + $(selects[0]).attr('data-product')).find('.attributes')[0];
    $(attribute).val(JSON.stringify(attributes));
};

$.fn.blockCart.updateCartCounter = function() {
    var urlCartCounter = Routing.generate('frontend.products.cart.counter') + '?nocache=' + Math.random() * 1000000;

    $.get(urlCartCounter, function (data) {
        cartCounter = document.getElementById('cart-counter');
        if (cartCounter !== null ) {
            cartCounter.textContent = ' (' + data.counter + ')';
        }
    });
};

$.fn.blockCart.setButtonEvent = function() {
    $('.btn-action-to-cart').on("click", function (event) {
        formId = $(this).attr('data-form-id');
        $('#' + formId).attr('data-action', $(this).attr('data-action'));
        $('#' + formId).submit();

        return false;
    });

    $('#file_to_cart_form_save').on('click', function(e) {
        e.preventDefault();
        input = $('input[type=file]')[0];
        var reader = new FileReader();
        reader.onload = function () {
            var text = reader.result;
            lines = text.split(/\n/);
            items = [];
            for(i=0;i<=lines.length-1;i++) {
                if (lines[i] != '') {
                    values = lines[i].split(';');
                    items.push({reference: values[0], quantity: values[1], attributes: values[2] !== undefined ? values[2] : ''});
                }
            }

            var url = Routing.generate('frontend.products.cart.save');
            data = {items: items};

            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: data,
                success: function (data) {
                    window.location.reload();
                }
            });
        };
        reader.readAsText(input.files[0]);

        return false;
    });

    $('.btn-action-select-all').on('click', function () {
        $('.cart-item-selected').prop('checked', true);
    });

    var modalConfirmItems;

    $('.cms-block-cart.form-jquery .btn-finish-order').on("click", function () {

        var forms = $(this).closest('.cms-block-cart').find('form');
        var data = {};
        var row;
        var sel;
        var allNotSelected = true;
        $(forms).each(function() {

            row = $(this).closest('.row-cart-item').find('.cart-item-selected');
            sel = row.is(':checked');

            //Only items selected
            if(row.length) {
                if (sel) {
                    q = $(this).find('input[name="add_to_cart_form[quantity]"]').val();
                    r = $(this).find('input[name="add_to_cart_form[quantity]"]').val();

                    if (q !== undefined && r !== undefined) {
                        data[$(this).attr('data-item-id')] = {'q': q, 'r': r};
                    }
                    comment = $(this).closest('.cms-block').find('textarea[name="comment_form[comment]"]').val();
                    if (comment !== undefined && comment != '') {
                        data['comment'] = comment;
                    }
                    method = $(this).closest('.cms-block').find('input[name="payment_method_form[method]"]:checked').val();
                    if (method !== undefined && method != '') {
                        data['method'] = method;
                    }
                    allNotSelected = false;
                }
            }
        });

        if(allNotSelected) {
            // $('#popupModalOrderConfirm').modal('show');
            message = Translator.trans("alert.label_no_items_selected");
            modalConfirmItems = $.alert({
                title: '',
                content: message,
                // autoClose: 'ok',
                theme: 'modern',
                type: 'success',
                buttons: {
                    ok: {
                        // isHidden: true,
                        text: Translator.trans("button.select_all"),
                        btnClass: 'btn-primary btn-action-select-all',
                        style: 'border: 100px solid black;',
                        action: function(){
                            modalConfirmItems.close();
                            $('.btn-action-select-all').trigger('click');
                        }
                    }
                }
            });

            return false;
        }

        var url = Routing.generate('frontend.products.cart.finish');
        $.ajax({
            url: url,
            type: 'POST',
            dataType: 'json',
            data: data,
            success: function (data) {
                if (data.status === "ok") {
                    $.fn.blockCart.updateCartCounter();
                    $('#item_added_to_cart_message').show();
                    message = Translator.trans("alert.label_finished_order");
                    // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                    $.alert({
                        title: Translator.trans("alert.label_alert_success"),
                        content: message,
                        autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'success',
                        buttons: {
                            ok: {
                                isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-primary'
                            }
                        }
                    });
                    window.location.replace(data.redirectUrl);
                } else {
                    message = Translator.trans("alert.label_failed_" + action + "_item");
                    // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                    $.alert({
                        title: Translator.trans("alert.label_alert_error"),
                        content: message,
                        // autoClose: 'ok|300',
                        theme: 'modern',
                        type: 'red',
                        buttons: {
                            ok: {
                                // isHidden: true,
                                text: Translator.trans("button.okey"),
                                btnClass: 'btn-danger'
                            }
                        }
                    });
                }
                console.log(message);
            }
        });

        return false;
    });
};

$.fn.blockCart.init = function() {
    $.fn.blockCart.updateCartCounter();
    $.fn.blockCart.setButtonEvent();
}

cart_component = {
    created: function() {
        if (this.preSave !== undefined) {
            if (!this.modefilth || (this.modefilth && this.modefilth == 'calc')) {
                process = this.preSave("calc", "order");
            } else if (this.preLoadPrices !== undefined) {
                process = this.preLoadPrices();
            }
        }
    },
    methods: {
        add: function(event, noPreAdd) {
            noPreAdd = noPreAdd !== undefined ? noPreAdd : true;
            if (event.preventDefault !== undefined) {
                event.preventDefault();
	    }
            
            //$('#loading').removeClass('d-none');

            // console.log('ADD TO CART',event);

            element = event.target;
            group = $(element.closest('.box-add-to-cart'))[0];
            reference = group.querySelector('[name="add_to_cart_form[reference]"]').value;
            if (this.cart != null) {
                for (const [key, item] of Object.entries(this.cart.items)) {
                    if (item.reference == reference) {
                        message = Translator.trans("alert.label_product_into_cart");
                        $.alert({
                            title: Translator.trans("alert.label_alert_error"),
                            content: message,
                            // autoClose: 'ok|300',
                            theme: 'modern',
                            type: 'red',
                            buttons: {
                                ok: {
                                    // isHidden: true,
                                    text: Translator.trans("button.okey"),
                                    btnClass: 'btn-danger'
                                }
                            }
                        });

                        // $('#loading').addClass('d-none');

                        return ;
                    }
                }
            }

            item = {
                reference: reference,
                quantity: group.querySelector('[name="add_to_cart_form[quantity]"]').value,
                extraData: group.querySelector('[name="add_to_cart_form[extraData]"]').value,
                source: productSource,
                price: 0,
                id: 0,
                productId: 0
            };

            if (this.preAdd !== undefined && noPreAdd) {
		that = this;
                this.preAdd(item, event, function() {
                    that.operate(item, event, that);
                });
                if (!this.allowBuy) {
                    return ;
                }
            } else {
                this.operate(item, event);
	    }

        },
        operate: function(item, event, that) {
            // console.log(event);
            if (event !== undefined) {

                selects = $(this)
                    .closest('.product')
                    .find('[data-select-attribute]');
                if (selects.length > 0) {
                    event = { target: $(selects[0]) };
                    $.fn.blockCart.changeAttribute(event);
                }
                if (event.preventDefault !== undefined) {
                    event.preventDefault();
                }
                var reference = item.reference;
                var source = item.source;
                var price = item.price;
                var extraData = item.extraData;
                let form = $(event.target).closest('form');
                if ($(form).length == 0) {
                    form = $(event.target).closest('.row-cart-item');
                }
                var stock = parseInt($(form).find('.reference').attr('data-stock'));
                var itemId = item.id;
                var quantity = parseInt(item.quantity);
                var action = $(event.target).attr('data-action');
                var noCheckAvailable = $(event.target).attr('data-no-check-available') !== undefined;
                var attributes = this.products !== undefined && this.products[item.productId] !== undefined &&
                    this.products[item.productId].attributes !== undefined
                    ? this.products[item.productId].attributes : [];
                
                item.price = 0;
                if (action == 'add') {
                    item.quantity = 0;
                    if (this.addedLast !== undefined && this.addedLast !== undefined) {
                        item.price = this.addedLast.price;
                    }
                }

                operate = true;
                if (action != 'remove') {
                    if (quantity > 0) {
                        if ((productSource == "cms" && price == 0) || (!noCheckAvailable && stock < quantity)) {
                            message = Translator.trans("alert.label_not_available");
                            $.alert({
                                title: Translator.trans("alert.label_alert_error"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'red',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-danger'
                                    }
                                }
                            });
                            operate = false;

                            $('#loading').addClass('d-none');
                        }
                    } else {
                        message = Translator.trans("alert.label_quantity_minimum");
                        // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                        $.alert({
                            title: Translator.trans("alert.label_alert_error"),
                            content: message,
                            // autoClose: 'ok|300',
                            theme: 'modern',
                            type: 'red',
                            buttons: {
                                ok: {
                                    // isHidden: true,
                                    text: Translator.trans("button.okey"),
                                    btnClass: 'btn-danger'
                                }
                            }
                        });
                        operate = false;

                        $('#loading').addClass('d-none');
                    }
                }
            }
            if (operate) {
                this.operate_cart_item(action, reference, attributes, source, quantity, item.price, itemId, this, extraData, event);
            }

            return false;
        },
        calc: function() {
            if (this.preSave !== undefined) {
                this.disableFinishButton = true;
                that = this;
                this.preSave("calc", "order", function() {
                    nP = 0;
                    if (this.cart != null) {
                        for (const [key, item] of Object.entries(this.cart.items)) {
                            if (parseInt(item.reference) > 0) {
                                nP++;
                            }
                        }
                    }
                    if (nP == 1) {
                        window.location.reload();
                    }
                    that.disableFinishButton = false;
                    that.modefilthview = 'calc';
                    that.titlecart = Translator.trans("block.label_summary_order");
                    that.$forceUpdate();
                });
            }
        },
        goInputMode: function() {
            this.modefilth = true;
            this.modefilthview = 'input';
            this.titlecart = Translator.trans("block.label_cart");
            this.$forceUpdate();
            if (this.postInputMode !== undefined) {
                this.postInputMode();
            }
        },
        operate_cart_item: function(action, reference, attributes, source, quantity, price, itemId, form, extraData, event) {

            var data = {
                'reference': reference, 'attributes': attributes, 'source': source, 'quantity': quantity, 'price': price,
                'itemId': itemId, 'extraData': extraData
            };

            if (action == "add" || (action == "update" && !this.modefilth)) {
                var url = Routing.generate('frontend.products.cart.item.quantity.' + action);
            } else if (action == "remove" || (action == "update" && this.modefilth)) {
                var url = Routing.generate('frontend.products.cart.item.remove');
            }

            that = this;
            $(event.target).attr('disabled', 'disabled');
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: data,
                success: function (data) {
                    if (data.status === true) {
                        //that.cart = data.cart;
                        if (action == "remove") {
                            that.cart.items = that.cart.items.filter(function(value, index, arr){
                                return value.reference != reference;
                            });
                        } else if (action == "add") {
                            if (that.cart !== undefined && that.cart !== null) {
                                data.cart.items = data.cart.items.forEach(function(value){
                                    if (value.reference == reference && that.cart !== undefined) {
                                        if (that.addedLast !== undefined && that.addedLast !== undefined) {
                                            value.basePrice = that.addedLast.price * 100;
                                        }
                                        that.cart.items.push(value);
                                    }
                                });
                            }
                        } else if (action == "update") {
                            that.cart.items = that.cart.items.filter(function(value, index, arr){
                                return value.reference != reference;
                            });
                        }
                        that.products = data.products;
                        $.fn.blockCart.updateCartCounter();
                        $('#item_added_to_cart_message').show();
                        if (action != "update" || (action == "update" && this.modefilth)) {
                            message = Translator.trans("alert.label_" + action + "_item");
                            // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_success"),
                                content: message,
                                autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'success',
                                buttons: {
                                    ok: {
                                        isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-primary'
                                    }
                                }
                            });
                        }

                        $('#loading').addClass('d-none');

                        if (that.preSave !== undefined) {
                            callback = function() {
                                nP = 0;
                                if (that.cart != null) {
                                    for (const [key, item] of Object.entries(that.cart.items)) {
                                        if (parseInt(item.reference) > 0) {
                                            nP++;
                                        }
                                    }
                                }
                                that.manualItem.reference = that.manualItem.quantity = '';
                                if (action == 'update' && that.modefilth) {
                                    that.manualItem.reference = reference;
                                    that.manualItem.quantity = quantity;
                                    if (form.$el !== undefined) {
                                        $(form.$el).find('input[name="add_to_cart_form[reference]"]').val(reference);
                                        $(form.$el).find('input[name="add_to_cart_form[quantity]"]').val(quantity);
                                        btn = $(form.$el).find('[name="add_to_cart_form[save]"]');
                                        event = { target: btn[0] };
                                        that.add(event);
                                    }                                    
                                }
                                that.processingOperation = false;
                                $(event.target).removeAttr('disabled');
                                /*
                                if (nP == 1) {
                                    window.location.reload();
                                }
                                */
                            };
                            if (!that.modefilth || (that.modefilth && that.modefilth == 'calc')) {
                                that.preSave("calc", "order", callback);
                            } else {
                                callback();
                            //} else if (that.preLoadPrices !== undefined) {
                                //process = that.preLoadPrices($callback);
                            }
                        }
                        editing = false;
                        Object.keys(that.cart.items).forEach(function(i){
                            if (that.cart.items[i].editing == true && that.cart.items[i].reference == reference) {
                                that.cart.items[i].editing = false;
                            }
                            if (that.cart.items[i].editing) {
                                editing = true;
                            }
                        });
                        that.disableFinishButton = editing;
                    } else {

                        failed = data.error !== undefined ? data.error : action + "_item";
                        message = Translator.trans("alert.label_failed_" + failed);
                        //$.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                        $.alert({
                            title: Translator.trans("alert.label_alert_error"),
                            content: message,
                            // autoClose: 'ok|300',
                            theme: 'modern',
                            type: 'red',
                            buttons: {
                                ok: {
                                    // isHidden: true,
                                    text: Translator.trans("button.okey"),
                                    btnClass: 'btn-danger'
                                }
                            }
                        });

                        $('#loading').addClass('d-none');
                    }

                    for (const [key, value] of Object.entries(data)) {
                        if (key.substr(0, 11) == "remove-row-") {
                            $(form).closest('.' + key.substr(7)).remove();
                        }
                    }

                    if (that.cart == null) {
                        window.location.reload();
                    }
                }
            });

            return false;
        },
        saveExtraData: function() {
            var url = Routing.generate('frontend.products.cart.save.extradata');
            itemExtraData = [];
            this.cart.items.forEach(function(item) {
                if (item.id !== undefined && item.extraData !== undefined) {
                    itemExtraData.push({cartItemId: item.id, extraData: item.extraData});
                }
            });
            data = {
                addressName: this.addressname,
                receiver: this.receiver,
                receiverPhone: this.receiverphone,
                street: this.street,
                postalCode: this.postalcode,
                locality: this.locality,
                region: this.region,
                country: this.country,
                addressId: this.addressid,
                purchaseOrder: this.purchaseorder,
                authorizationNumber: this.authorizationnumber,
                comments: this.comments,
                customerGroupId: this.customergroupid,
                itemExtraData: itemExtraData
            };
            $.ajax({
                url: url,
                type: 'POST',
                dataType: 'json',
                data: data,
                success: function (data) {
                    console.log('saving...', data)
                }
            });

        },
        changeReference: function() {
            if (this.preChangeReference !== undefined) {
                this.preChangeReference(this.manualItem.reference);
            }
            this.quantity = 0;
            this.allowBuy = false;
        },
        finishOrder: function(action, document, event) {
            
            $('#loading').removeClass('d-none');
            this.disableFinishButton = true;
            $(event.target).attr('disabled', 'disabled');

            target = event.target;
            var forms = $(target).closest('.cms-block-cart').find('form');
            var data = {};
            $(forms).each(function() {
                q = $(target).find('input[name="add_to_cart_form[quantity]"]').val();
                r = $(target).find('input[name="add_to_cart_form[quantity]"]').val();
                if (q !== undefined && r !== undefined) {
                    data[$(target).attr('data-item-id')] = {'q': q, 'r': r};
                }
                comment = $(target).closest('.cms-block').find('textarea[name="comment_form[comment]"]').val();
                if (comment !== undefined && comment != '') {
                    data['comment'] = comment;
                }
                method = $(target).closest('.cms-block').find('input[name="payment_method_form[method]"]:checked').val();
                if (method !== undefined && method != '') {
                    data['method'] = method;
                }
            });

            var url = Routing.generate('frontend.products.cart.finish');

            saveOrder = function () {
                $.ajax({
                    url: url,
                    type: 'POST',
                    dataType: 'json',
                    data: data,
                    success: function (data) {
                        if (data.status === "ok") {
                            $.fn.blockCart.updateCartCounter();
                            $('#item_added_to_cart_message').show();
                            message = Translator.trans("alert.label_finished_" + document);
                            // $.growl.notice({ title: Translator.trans("alert.label_alert_success"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_success"),
                                content: message,
                                autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'success',
                                buttons: {
                                    ok: {
                                        isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-primary'
                                    }
                                }
                            });
                            window.location.replace(data.redirectUrl);
                        } else {
                            message = Translator.trans("alert.label_failed_" + action + "_item");
                            // $.growl.error({ title: Translator.trans("alert.label_alert_error"), message: message });
                            $.alert({
                                title: Translator.trans("alert.label_alert_error"),
                                content: message,
                                // autoClose: 'ok|300',
                                theme: 'modern',
                                type: 'red',
                                buttons: {
                                    ok: {
                                        // isHidden: true,
                                        text: Translator.trans("button.okey"),
                                        btnClass: 'btn-danger'
                                    }
                                }
                            });
                            this.disableFinishButton = false;
                            $('#loading').addClass('d-none');
                            $(event.target).removeAttr('disabled');
                        }
                        console.log(message);
                    }
                });
            }

            if (productSource == "pim") {
                if (this.preSave !== undefined) {
                    this.preSave(action, document, action=="save" ? saveOrder : null, event);
                }
            } else {
                saveOrder();
            }

            return false;

        },
        checkDefaultAddress: function(){

            this.addressname = '';
            this.receiver = '';
            this.receiverphone = '';
            this.street = '';
            this.locality = '';
            this.postalcode = '';
            this.region = '';
            this.country = '';
            this.$forceUpdate();

            if(this.postCheckDefaultAddress != undefined){
                this.postCheckDefaultAddress();
            }
        },
        addManualAddress: function(){
            if(this.postAddManualAddress != undefined){
                this.postAddManualAddress();
            }
        },
        useDefaultAddress: function(){
            if(this.postUseDefaultAddress != undefined){
                this.postUseDefaultAddress();
            }
        }
    },
    filters: {
        getValueFromArray: function (array, field, defaultField) {
            if (array !== undefined && array[field] !== undefined) {
                if (array[field] !== '') {
                    return array[field];
                } else {
                    if (array !== undefined && array[defaultField] !== undefined) {
                        if (array[defaultField] !== '') {
                            return array[defaultField];
                        }
                    }

                }
            }

            return'';
        },
        numberProducts: function(items) {
            return items.filter(function(e) { return e.additionalLine === undefined; }).length;
        }
    },
    data: {
        disableFinishButton: false,
        addresses: [],
        customergroups: [],
        manualAddress: false,
        manualItem: {
            reference: '',
            quantity: ''
        },
        allowBuy: false
    }
};
